import React, { } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CartSuccessMobile from "../components/Cart/CartGrid/CartSuccessMobile/cartSuccessMobile";
import PayedContainer from "../components/PayedContainer/payedContainer";

const PayedPage = () => {
    return (
        <Layout>
            <SEO title="Заказ оплачен" />
            <PayedContainer>
                <CartSuccessMobile />
            </PayedContainer>
        </Layout>
    )
}

export default PayedPage
