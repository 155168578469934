import { CartSuccessMobileLink } from './cartSuccessMobile.module.css';
import H2 from '../../../Blocks/H2/H2';
import Paragraph from '../../../Blocks/Paragraph/paragraph';
import LinkTo from '../../../Blocks/LinkTo/linkTo';
import React from 'react';

const CartSuccessMobile = () => (
  <div style={{ marginBottom: '50px', maxWidth: '800px' }}>
    <H2>Благодарим за ваш заказ!</H2>
    <Paragraph>
      Можете закрыть окно и вернутся в приложение, статус заказа появится в профиле через несколько минут.
    </Paragraph>
  </div>
);

export default CartSuccessMobile;
